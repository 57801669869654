<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12" md="4" lg="2" v-if="!all">
          <v-select
            dense
            outlined
            flat
            background-color="white"
            :items="dropDownCurrency"
            v-model="params.id"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="3">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.startDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="params.startDate"
              @input="menuStartDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="3">
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.endDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="params.endDate" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="2" class="py-0">
          <v-row>
            <v-col><v-checkbox v-model="all" label="ALL"></v-checkbox></v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="2">
          <v-btn color="primary" @click="getReports" class="mr-2">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
          <v-btn color="success" @click="excel"> <v-icon>mdi-file-excel</v-icon></v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">Account Number</th>
              <th class="text-center">Account Name</th>
              <th class="text-center">Begin Balance</th>
              <th class="text-center">Debit</th>
              <th class="text-center">Credit</th>
              <th class="text-center">Net Difference</th>
              <th class="text-center">End Balance</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in report.items" :key="item.accountNumber">
              <td class="text-center">{{ item.accountNumber }}</td>
              <td>{{ item.accountName }}</td>
              <td class="text-right">{{ formatPriceBegin(item) }}</td>
              <td class="text-right">{{ formatPrice(item.transactionDebit) }}</td>
              <td class="text-right">{{ formatPrice(item.transactionCredit) }}</td>
              <td class="text-right">{{ formatPrice(item.netDifference) }}</td>
              <td class="text-right">{{ formatPriceEnd(item) }}</td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td class="text-right">
                {{ formatPrice(report.totalBeginDebit - report.totalBeginCredit) }}
              </td>
              <td class="text-right">{{ formatPrice(report.totalTransactionDebit) }}</td>
              <td class="text-right">{{ formatPrice(report.totalTransactionCredit) }}</td>
              <td class="text-right"></td>
              <td class="text-right">
                {{ formatPrice(report.totalEndDebit - report.totalEndCredit) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import fileDownload from "js-file-download";

export default {
  name: "report-trial-balance-v2",
  data() {
    return {
      params: {
        startDate: moment()
          .clone()
          .startOf("month")
          .format("yyyy-MM-DD"),
        endDate: moment()
          .clone()
          .endOf("month")
          .format("yyyy-MM-DD"),
        id: null,
        transaction: true,
      },
      menuStartDate: false,
      menuEndDate: false,
      report: {},
      all: false,
    };
  },
  methods: {
    async getReports() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/trialBalanceV2", this.params)
        .then(response => {
          this.report = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatPriceBegin(item) {
      if (item.beginDebit > 0) {
        return this.formatPrice(item.beginDebit);
      } else {
        return "-" + this.formatPrice(item.beginCredit);
      }
    },
    formatPriceEnd(item) {
      if (item.endDebit > 0) {
        return this.formatPrice(item.endDebit);
      } else {
        return "-" + this.formatPrice(item.endCredit);
      }
    },
    async excel() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/getExcelTrialBalanceV2", this.params)
        .then(response => {
          fileDownload(response.data, `trial-balance.xlsx`);
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
  computed: {
    ...mapState("currency", ["dropDownCurrency"]),
    setId() {
      if (this.all) {
        return 0;
      }
      return null;
    },
  },
  watch: {
    setId(val) {
      this.params.id = val;
    },
  },
  mounted() {
    this.$store.dispatch("currency/getDropDownCurrency");
  },
};
</script>

<style scoped>
table,
th,
td {
  border: 1px solid grey;
}
</style>
